<script lang="ts">
	import { twMerge } from 'tailwind-merge';

	import checkImage from '$lib/images/check-white.svg';

	const wrapperClasses = twMerge(
		'flex w-fit cursor-pointer select-none items-center gap-5',
		$$props.class
	);
</script>

<label class={wrapperClasses}>
	<input class="peer left-0 top-0 hidden opacity-0" type="checkbox" on:change {...$$restProps} />
	<span
		class="rounded-md border border-border bg-darkgrey
      p-2 peer-checked:border-transparent peer-checked:bg-teal">
		<img class="h-4 w-4 opacity-0 transition-opacity" src={checkImage} alt="check" />
	</span>
	<slot class="checkbox-label-container" />
</label>

<style>
	input:checked ~ span img {
		opacity: 1;
	}
</style>
